// 取token
export function getToken() {
  return sessionStorage.getItem("token");
}
// 存token
export function setToken(token) {
  return sessionStorage.setItem("token", token);
}

// 取userId
export function getUserId() {
  return sessionStorage.getItem("userId");
}
// 存userId
export function setUserId(userId) {
  return sessionStorage.setItem("userId", userId);
}
