// 引入
import Vue from "vue";
import Router from "vue-router";
import { getToken } from "../utils/LocalStorage";

// 注册
Vue.use(Router);

// 路由结构
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  // 贷款信息
  {
    path: "/home",
    component: () => import("@/views/Home/Home.vue"),
  },
  // 还款中
  {
    path: "/repayment",
    component: () => import("@/views/Repayment/Repayment.vue"),
  },
  // 逾期中
  {
    path: "/beoverdue",
    component: () => import("@/views/BeOverdue/BeOverdue.vue"),
  },
  // 结清中
  {
    path: "/settle",
    component: () => import("@/views/Settle/Settle.vue"),
  },

  // 借款人认证
  {
    path: "/login",
    component: () => import("@/views/Login/Login.vue"),
  },
];

// 实例化
const router = new Router({
  routes,
});

// 全局路由守卫;
router.beforeEach((to, from, next) => {
  // 判断是否存在token
  if (getToken()) {
    next();
  } else {
    if (to.path == "/login") {
      next();
    } else {
      next({ path: "/login" });
    }
  }
});

// 主动给滚动到顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
