import Vue from "vue";
import App from "./App.vue";
//引入 Vant
import Vant from "vant";
import "vant/lib/index.css";
// 引入路由
import router from "./router/index";
// 引入重置样式
import "./utils/reset.css";

Vue.use(Vant);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
